import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, retry, catchError } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DropdownService {
  // Base url
  private readonly baseurl = environment.apiUrl;

  constructor(private readonly http: HttpClient) {}

  updateCompanie(id_compania: string): Observable<any> {
    return this.http
      .post<any>(`${this.baseurl}/fundcore/cambiar_compania`, {
        id_compania: id_compania,
      })
      .pipe(retry(1), catchError(this.errorHandler));
  }

  listarSucursales(): Observable<any> {
    return this.http
      .post<any>(`${this.baseurl}/puntorecaudo/listar_puntos_recaudo`, {
        test: 'test',
      })
      .pipe(retry(1), catchError(this.errorHandler));
  }

  listarTiposDeRecibo(): Observable<any> {
    return this.http
      .post<any>(
        `${this.baseurl}/puntorecaudo/listar_tipos_documento_recaudo`,
        {
          test: 'test',
        }
      )
      .pipe(retry(1), catchError(this.errorHandler));
  }

  listarCajas(id_punto: any): Observable<any> {
    return this.http
      .post<any>(`${this.baseurl}/puntorecaudo/listar_cajas_recaudo`, {
        punto_recaudo: id_punto,
      })
      .pipe(retry(1), catchError(this.errorHandler));
  }

  listarLineasCreditoRecaudo() {
    return this.http
      .post<any>(`${this.baseurl}/puntorecaudo/listar_lineas_credito_recaudo`, {
        test: 'test',
      })
      .pipe(retry(1), catchError(this.errorHandler));
  }

  listarMotivosAnulacion() {
    return this.http
      .post<any>(`${this.baseurl}/puntorecaudo/listar_motivos_anulacion`, {
        test: 'test',
      })
      .pipe(retry(1), catchError(this.errorHandler));
  }

  // Lanza una excepción `Error` tipo client-side o server-side
  private errorHandler(error: HttpErrorResponse): never {
    let errorMessage = 'Unknown error occurred';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    throw new Error(errorMessage);
  }
}
