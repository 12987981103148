import { Component, OnInit } from '@angular/core';

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

import { LoginService } from '../../features/login/services/login.service';
import { NavigationEnd, Router } from '@angular/router';
import { DropdownService } from '../../core/services/dropdown.service';
import { NotificationService } from '../../core/services/notification.service';
import { filter } from 'rxjs';
import { ConfirmDialogComponent } from '@core/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { LocalStorage } from '@core/utils/local-storage';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
})
export class AdminLayoutComponent implements OnInit {
  // accordion

  panels: boolean[] = [false, false, false, false, false, false];

  togglePanel(index: number) {
    this.panels[index] = !this.panels[index];
  }

  checkActivePanels() {
    const activeRoute = this.router.url;
    if (activeRoute.includes('home')) {
      this.showOrganizacion = true;
      this.showCaja = false;
      this.showSucursal = false;
      this.showTipoRecibo = false;
    }
    if (activeRoute.includes('creditos')) {
      this.panels[0] = true;
      this.showOrganizacion = true;
      this.showCaja = false;
      this.showSucursal = false;
      this.showTipoRecibo = false;
    }
    if (activeRoute.includes('recaudo/')) {
      this.panels[1] = true;
      this.showOrganizacion = true;
      this.showCaja = false;
      this.showSucursal = false;
      this.showTipoRecibo = false;
    }
    if (activeRoute.includes('recaudo/punto-recaudo')) {
      this.panels[1] = true;
      this.showOrganizacion = false;
      this.showCaja = true;
      this.showSucursal = true;
      this.showTipoRecibo = true;
    }
    if (activeRoute.includes('usuarios')) {
      this.panels[2] = true;
      this.showOrganizacion = true;
      this.showCaja = false;
      this.showSucursal = false;
      this.showTipoRecibo = false;
    }
    if (activeRoute.includes('roles')) {
      this.panels[2] = true;
      this.showOrganizacion = true;
      this.showCaja = false;
      this.showSucursal = false;
      this.showTipoRecibo = false;
    }
    if (activeRoute.includes('configuracion')) {
      this.panels[3] = true;
      this.showOrganizacion = true;
      this.showCaja = false;
      this.showSucursal = false;
      this.showTipoRecibo = false;
    }

    if (activeRoute.includes('companias')) {
      this.panels[4] = true;
      this.showOrganizacion = true;
      this.showCaja = false;
      this.showSucursal = false;
      this.showTipoRecibo = false;
    }

    if (activeRoute.includes('prestatarios')) {
      this.panels[5] = true;
      this.showOrganizacion = true;
      this.showCaja = false;
      this.showSucursal = false;
      this.showTipoRecibo = false;
    }
    if (activeRoute.includes('reportes')) {
      this.panels[6] = true;
      this.showOrganizacion = true;
      this.showCaja = false;
      this.showSucursal = false;
      this.showTipoRecibo = false;
    }
  }
  //SelectionController
  showSucursal: boolean = false;
  showCaja: boolean = false;
  showTipoRecibo: boolean = false;
  showOrganizacion: boolean = false;

  user: any;
  permisos: any;
  //Dropdowns
  companies: any[] = [];
  selectedCompanie: string = '';
  cajas: any[] = [];
  selectedCaja: string = '';
  sucursales: any[] = [];
  selectedSucursal: string = '';
  tiposDeRecibos: any[] = [];
  selectedTipoRecibo: string = '';

  constructor(
    private accountService: LoginService,
    private dialog: MatDialog,
    private router: Router,
    private dropdownService: DropdownService,
    private observer: BreakpointObserver,
    private notificationService: NotificationService
  ) {
    //Accordion
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.checkActivePanels();
      });

    //SideNav
    this.observer.observe(['(max-width: 800px)']).subscribe((screenSize) => {
      if (screenSize.matches) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    });
    this.user = this.accountService.userValue;
    this.permisos = this.accountService.permisosValue;
    this.companies = this.user.companias;
    this.listarDropDowns();
  }

  isMobile = true;
  isCollapsed = false;

  ngOnInit(): void {}

  tienePermiso(modulo: string, permiso: string): boolean {
    return (
      this.permisos &&
      this.permisos[modulo] &&
      this.permisos[modulo][permiso] === '1'
    );
  }

  cerrarSesion() {
    this.accountService.logout();
  }

  onCompanieChange() {
    //Cambia en el back la compañia seleccionada

    this.dropdownService.updateCompanie(this.selectedCompanie).subscribe({
      next: (data: any) => {
        if (data.success) {
          LocalStorage.setCompaniaConfig({
            asociaSiesa: data.data[0].asociar_siesa,
            asociaRedeban: data.data[0].asociar_redeban,
          });
          LocalStorage.setCompaniaActiva(this.selectedCompanie);
          this.listarDropDowns();
          window.location.reload();
          this.notificationService.openSnackBar(data.message, 'info');
        } else {
          this.notificationService.openSnackBar(data.message, 'error');
        }
      },
      error: (error: { message: any }) => {
        this.notificationService.openSnackBar(error.message, 'error');
      },
    });
    //Cambia en el localstorage la compañia seleccionada
  }

  listarDropDowns() {
    this.limpiarDropDrowns();
    if (!LocalStorage.getCompaniaActiva()) {
      this.selectedCompanie = this.companies[0].id_compania;
      LocalStorage.setCompaniaActiva(this.selectedCompanie);
    } else {
      this.selectedCompanie = LocalStorage.getCompaniaActiva();
    }

    this.dropdownService.updateCompanie(this.selectedCompanie).subscribe({
      next: (data: any) => {
        if (data.success) {
          LocalStorage.setCompaniaConfig({
            asociaSiesa: data.data[0].asociar_siesa,
            asociaRedeban: data.data[0].asociar_redeban,
          });
          this.notificationService.openSnackBar(data.message, 'info');
        } else {
          LocalStorage.setCompaniaConfig({
            asociaSiesa: false,
            asociaRedeban: false,
          });
          this.notificationService.openSnackBar(data.message, 'error');
        }
      },
    });

    this.dropdownService.listarSucursales().subscribe({
      next: (data: any) => {
        if (data.success) {
          this.sucursales = data.data;
          // this.selectedSucursal = this.sucursales[0].id_punto;
          // this.onSucursalChange(this.selectedSucursal);
        } else {
          this.notificationService.openSnackBar(data.message, 'error');
        }
      },
      error: (error: { message: any }) => {
        this.notificationService.openSnackBar(error.message, 'error');
      },
    });

    this.dropdownService.listarTiposDeRecibo().subscribe({
      next: (data: any) => {
        if (data.success) {
          this.tiposDeRecibos = data.data;
        } else {
          this.notificationService.openSnackBar(data.message, 'error');
        }
      },
      error: (error: { message: any }) => {
        this.notificationService.openSnackBar(error.message, 'error');
      },
    });
  }

  onSucursalChange(id_punto: any) {
    this.cajas = [];
    this.dropdownService.listarCajas(id_punto).subscribe({
      next: (data: any) => {
        if (data.success) {
          this.cajas = data.data;
          // this.selectedCaja = this.cajas[0].id_caja;
        } else {
          this.notificationService.openSnackBar(data.message, 'error');
        }
      },
      error: (error: { message: any }) => {
        this.notificationService.openSnackBar(error.message, 'error');
      },
    });
  }

  limpiarDropDrowns() {
    this.sucursales = [];
    this.selectedSucursal = '';
    this.cajas = [];
    this.selectedCaja = '';
    this.tiposDeRecibos = [];
    this.selectedTipoRecibo = '';
  }

  abrirDialogoConfirmacion() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '450px',
      height: '200px',
      data: { title: '¿Estás seguro(a)?' },
    });

    dialogRef.afterClosed().subscribe((acepto) => {
      if (acepto) {
        this.cerrarSesion();
      }
    });
  }
}
